import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import HeroBanner from "../../layouts/HeroBanner"
import { getCaseStudyLayout } from "../../get-case-study-layout-utils"

export const query = graphql`
    query CaseStudyQuery($id: String!) {
        wpCaseStudy(id: { eq: $id }) {
            title
            date
            content
            featuredImage {
                node {
                    sourceUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            seo {
                canonical
                title
                focuskw
                metaDesc
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    title
                }
                opengraphModifiedTime
                opengraphPublishedTime
                opengraphPublisher
                opengraphSiteName
                opengraphTitle
                opengraphType
                opengraphUrl
                twitterDescription
                twitterTitle
                twitterImage {
                    altText
                    sourceUrl
                    title
                }
            }
            id
            uri
            caseStudiesBuilder {
                layouts {
                    ... on WpCaseStudy_Casestudiesbuilder_Layouts_BannerImage {
                        ...BannerImage
                    }
                    ... on WpCaseStudy_Casestudiesbuilder_Layouts_MiscContent {
                        ...MiscContentCaseStudy
                    }
                    ... on WpCaseStudy_Casestudiesbuilder_Layouts_CaseStudyTestimonial {
                        ...CaseStudyTestimonial
                    }
                    ... on WpCaseStudy_Casestudiesbuilder_Layouts_BeforeAfterSlider {
                        ...BeforeAfterSlider
                    }
                }
                pageDescription
                pageLink {
                    title
                    url
                }
            }
            translation {
                englishUrl
            }
            caseStudyCategories {
                nodes {
                    name
                }
            }
        }
    }
`

const CaseStudy = ({ data }) => {
  const { seo, title, caseStudiesBuilder, caseStudyCategories, translation } =
    data.wpCaseStudy
  const layouts = caseStudiesBuilder?.layouts || []
  return (
    <Layout seo={seo} englishUrl={translation?.englishUrl}>
      <HeroBanner
        companyName={title}
        title={caseStudiesBuilder.pageDescription}
        link={caseStudiesBuilder.pageLink}
        services={caseStudyCategories.nodes}
      />
      {layouts.map(layout => getCaseStudyLayout(layout))}
    </Layout>
  )
}

export default CaseStudy
